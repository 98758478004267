<template>
  <!-- 首页主体区域 -->
  <div class="box">
    <!-- 首页大图轮播show -->
    <!-- <div class="banner"></div> -->
    <!-- 轮播 -->
    <div
      class="swiper_box"
      ref="swiperBox"
      @mouseenter="enter"
      @mouseleave="leave"
    >
      <el-carousel
        indicator-position="none"
        :interval="3000"
        autoplay
        arrow="always"
      >
        <el-carousel-item v-for="item in pics01" :key="item.url">
          <!-- 加上这句第一张会短一些 -->
          <!-- <div style=" background-image: url(item.url);background-size: cover;"></div> -->
          <!-- <img :src="item.url" style="width:100%;height:100%"> -->
          <img :src="item.url" style="width:100%;" />
          <!-- <el-image style="width:100%" :src="item.url"  fit="scale-down"></el-image> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 数字动态滚动 -->
    <div class="scrollNum" ref="NumBox">
      <div class="aaa"></div>
      <div class="scrollNum_box1">
        <p class="scrollNum_box_topstyle" ref="Num001">
          <span class="scrollNum_box_topstyle_num" ref="bigerNum01">
            <span v-show="this.showNub == true">1.0</span>
            <countTo
              v-show="this.showNub == false"
              :startVal="startVal01"
              :endVal="endVal01"
              :duration="1000"
              :decimals="1"
            ></countTo>
          </span>
          <span class="scrollNum_box_topstyle_txt" ref="Txt01">秒</span>
        </p>
        <div class="scrollNum_box_bottomstyle">
          <p class="scrollNum_box_bottomstyle_txt" ref="Txt03">商品识别</p>
        </div>
      </div>
      <div class="scrollNum_box2">
        <div class="bbb"></div>
        <p class="scrollNum_box_topstyle" ref="Num002">
          <span class="scrollNum_box_topstyle_num" ref="bigerNum02">
            <span v-show="this.showNub == true">6.0</span>
            <countTo
              v-show="this.showNub == false"
              :startVal="startVal02"
              :endVal="endVal02"
              :duration="1000"
              :decimals="1"
            ></countTo>
          </span>
          <span class="scrollNum_box_topstyle_txt" ref="Txt02">秒</span>
        </p>
        <div class="scrollNum_box_bottomstyle">
          <p class="scrollNum_box_bottomstyle_txt" ref="Txt04">快速结算</p>
        </div>
      </div>
      <div class="scrollNum_box3">
        <p class="scrollNum_box_topstyle" ref="Num003">
          <span class="scrollNum_box_topstyle_num" ref="bigerNum03">
            <span v-show="this.showNub == true">99.9</span>
            <countTo
              v-show="this.showNub == false"
              :startVal="startVal03"
              :endVal="endVal03"
              :duration="1000"
              :decimals="1"
            ></countTo>
          </span>
          <span class="scrollNum_box_topstyle_num" ref="Txt07">%</span>
        </p>
        <div class="scrollNum_box_bottomstyle">
          <p class="scrollNum_box_bottomstyle_txt" ref="Txt05">鉴别准确率</p>
        </div>
      </div>
      <div class="scrollNum_box4">
        <p class="scrollNum_box_topstyle" ref="Num004">
          <span class="scrollNum_box_topstyle_num" ref="bigerNum04">
            <span v-show="this.showNub == true">100000</span>
            <countTo
              v-show="this.showNub == false"
              :startVal="startVal04"
              :endVal="endVal04"
              :duration="1000"
            ></countTo>
          </span>
          <span class="scrollNum_box_topstyle_num" ref="Txt08">+</span>
        </p>
        <div class="scrollNum_box_bottomstyle">
          <p class="scrollNum_box_bottomstyle_txt" ref="Txt06">SKU</p>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <!-- 标题 -->
      <div
        :class="{ tipBefore: true, tipAfter: tipShowSolution }"
        ref="solutionRef"
      >
        <div class="solution_title">
          <div>解决方案</div>
        </div>
        <div class="solution_cont" style="margin-bottom: 3.125rem;">
          <div>
            技术赋能 · 营销引擎 · 贴心服务 · 保驾护航
          </div>
        </div>
      </div>
      <!-- 主体内容区域 -->
      <div class="solution_cards pcOnly">
        <el-button-group>
          <el-button
            :type="showleftf"
            icon="el-icon-arrow-left"
            @click="showLeft()"
            ref="solubtn01"
          >
          </el-button>
          <!-- <el-button :type="showleftf" @click="showLeft"><i class="el-icon-arrow-left el-icon--left"></i></el-button> -->
          <!-- 1.支付数字化解决方案 -->
          <div
            v-show="this.selectnum == true"
            class="solution_select"
            ref="solutionbox01"
          >
            <div class="solution_select_left">
              <div class="solution_select_left_layerone" ref="sonum01">
                <!-- <el-image style="width:100%" :src="require('../assets/pay1num.png')" fit="scale-down"></el-image> -->
                <img src="../assets/pay1num.png" style="width:100%;" />
              </div>
              <div class="solution_select_left_contentMix" ref="sonum02">
                <div class="solution_select_left_content" ref="sonum03">
                  <div class="solution_select_left_contentone" ref="sonum04">
                    <div
                      class="solution_select_left_contentone_top"
                      ref="sonum05"
                    >
                      支付数字化解决方案
                    </div>
                    <div
                      class="solution_select_left_contentone_bot"
                      ref="sonum06"
                    >
                      Digital Payment Solutions
                    </div>
                  </div>
                  <div class="solution_select_left_contenttwo" ref="sonum07">
                    <div
                      class="solution_select_left_contenttwoMin"
                      ref="sonum08"
                    >
                      <div style="width:47px" ref="sonum09">
                        <img src="../assets/pay1bao.png" style="width:100%;" />
                        <!-- <el-image style="width:100%" :src="require('../assets/pay1bao.png')" fit="scale-down"></el-image> -->
                      </div>
                      <div
                        ref="sonum10"
                        style="font-size: 1.25rem;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;"
                      >
                        AI防盗损
                      </div>
                    </div>
                    <div
                      class="solution_select_left_contenttwoMin"
                      ref="sonum11"
                    >
                      <div style="width:46px" ref="sonum12">
                        <!-- <el-image style="width:100%" :src="require('../assets/pay1M.png')" fit="scale-down"></el-image> -->
                        <img src="../assets/pay1M.png" style="width:100%;" />
                      </div>
                      <div
                        ref="sonum13"
                        style="font-size: 1.25rem;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;"
                      >
                        结算智能化
                      </div>
                    </div>
                  </div>
                  <div
                    class="solution_select_left_contentonelook"
                    ref="sonum14"
                  >
                    <div
                      ref="sonum15"
                      class="solution_select_left_contentonelook_btn"
                      @click="jumpSolvePay"
                    >
                      查看更多
                    </div>
                  </div>
                </div>
              </div>
              <div class="solution_select_left_layertwo" ref="sonum16">
                <!-- <el-image style="width:100%" :src="require('../assets/pay1background.png')" fit="scale-down"></el-image> -->
                <img src="../assets/pay1background.png" style="width:100%;" />
              </div>
            </div>
            <div class="solution_select_right">
              <!-- <div class="ggg"></div> -->
              <!-- 上面那层 -->
              <div class="solution_select_right_box">
                <!-- 包裹的大盒子 -->
                <div
                  class="box01"
                  v-on:mouseenter="mouChange01($event)"
                  v-on:mouseleave="mouChange001($event)"
                >
                  <!-- 分析动画 -->
                  <!-- 动画一共动了三个部位  1.标题这个盒子 2. 阴影作为一个图层 3 icon内容部分的大盒子 -->
                  <!-- 包裹盒子 -->
                  <div
                    class="threebone"
                    :class="mouseenter01 == true ? 'change01' : 'change001'"
                  >
                    <!-- 左侧文字标题 -->
                    <div class="solution_select_right_box_left01">
                      <div style="width:1.5rem">AI防盗损</div>
                    </div>
                    <!-- 右侧内容 -->
                    <div class="solution_select_right_box_right">
                      <!-- 覆盖的阴影盒子 -->
                      <div class="shadow_box"></div>
                      <div class="solution_select_right_box_right_absolute">
                        <div class="solution_select_right_box_right_flex">
                          <div class="solution_select_right_box_right_iconbox">
                            <div style="width:2.5rem;height:2.5rem">
                              <img
                                src="../assets/paylookicon.png"
                                style="width:100%;height:100%"
                              />
                            </div>
                            <div>机器视觉</div>
                          </div>
                          <div class="solution_select_right_box_right_iconbox">
                            <div style="width:2.5625rem;height:2.625rem">
                              <img
                                src="../assets/paylookicon02.png"
                                style="width:100%;height:100%"
                              />
                            </div>
                            <div>深度学习</div>
                          </div>
                          <div class="solution_select_right_box_right_iconbox">
                            <div style="width:2.5rem;height:2.5rem">
                              <img
                                src="../assets/paylookicon03.png"
                                style="width:100%;height:100%"
                              />
                            </div>
                            <div>多传感器融合</div>
                          </div>
                          <div class="solution_select_right_box_right_iconbox">
                            <div style="width:2.5rem;height:2.5rem">
                              <img
                                src="../assets/paylookicon04.png"
                                style="width:100%;height:100%"
                              />
                            </div>
                            <div>反欺诈识别</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 下面那层 -->
              <div class="solution_select_right_box" style="top:50%;left:0;">
                <!-- 包裹的盒子 -->
                <div
                  class="box02"
                  v-on:mouseenter="mouChange02($event)"
                  v-on:mouseleave="mouChange002($event)"
                >
                  <!-- 左侧文字标题 -->
                  <div
                    class="solution_select_right_box_left02"
                    :class="mouseenter02 == true ? 'change03' : 'change003'"
                  >
                    <div style="width:1.5rem">结算智能化</div>
                  </div>
                  <!-- 右侧内容 -->
                  <div
                    class="solution_select_right_box_right02"
                    :class="mouseenter02 == true ? 'change04' : 'change004'"
                  >
                    <!-- 覆盖的阴影盒子 -->
                    <div class="shadow_box"></div>
                    <div class="solution_select_right_box_right_absolute">
                      <div class="solution_select_right_box_right_flex">
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.5rem;height:2.375rem">
                            <img
                              src="../assets/paylookicon05.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>扫码购买</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.625rem;height:2.625rem">
                            <img
                              src="../assets/paylookicon06.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>刷脸支付</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.25rem;height:2.3125rem">
                            <img
                              src="../assets/paylookicon07.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>智能闸机</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.6875rem;height:2.6875rem">
                            <img
                              src="../assets/paylookicon08.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>买完即走</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 2.营销数字化解决方案 -->
          <div
            v-show="this.selectnum == false"
            class="solution_select"
            ref="solutionbox02"
          >
            <div class="solution_select_left">
              <div
                class="solution_select_left_layerone"
                ref="sonum001"
                style="width:110px;"
              >
                <!-- <el-image style="width:100%" :src="require('../assets/pay2nam.png')" fit="scale-down"></el-image> -->
                <img src="../assets/pay2nam.png" style="width:100%;" />
              </div>
              <div class="solution_select_left_contentMix" ref="sonum002">
                <div class="solution_select_left_content" ref="sonum003">
                  <div class="solution_select_left_contentone" ref="sonum004">
                    <div
                      class="solution_select_left_contentone_top"
                      ref="sonum005"
                    >
                      营销数字化解决方案
                    </div>
                    <div
                      class="solution_select_left_contentone_bot"
                      ref="sonum006"
                    >
                      Digital Payment Solutions
                    </div>
                  </div>
                  <div class="solution_select_left_contenttwo" ref="sonum007">
                    <div
                      class="solution_select_left_contenttwoMin"
                      ref="sonum008"
                    >
                      <div style="width:52px;" ref="sonum009">
                        <img src="../assets/pay2mai.png" style="width:100%;" />
                        <!-- <el-image style="width:100%" :src="require('../assets/pay2mai.png')" fit="scale-down"></el-image> -->
                      </div>
                      <div
                        ref="sonum010"
                        style="font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;"
                      >
                        卖场数字化
                      </div>
                    </div>
                    <div
                      class="solution_select_left_contenttwoMin"
                      ref="sonum011"
                    >
                      <div style="width:53px;" ref="sonum012">
                        <img src="../assets/pay2ying.png" style="width:100%;" />
                        <!-- <el-image style="width:100%" :src="require('../assets/pay2ying.png')" fit="scale-down"></el-image> -->
                      </div>
                      <div
                        ref="sonum013"
                        style="font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;"
                      >
                        营销多样化
                      </div>
                    </div>
                  </div>
                  <div
                    class="solution_select_left_contentonelook"
                    ref="sonum014"
                  >
                    <div
                      class="solution_select_left_contentonelook_btn"
                      @click="jumpSolve"
                      ref="sonum015"
                    >
                      查看更多
                    </div>
                  </div>
                </div>
              </div>
              <div class="solution_select_left_layertwo" ref="sonum016">
                <img src="../assets/pay22background.png" style="width:100%;" />
                <!-- <el-image style="width:100%" :src="require('../assets/pay22background.png')" fit="scale-down"></el-image> -->
              </div>
            </div>
            <div class="solution_select_right">
              <!-- <div class="ggg"></div> -->
              <!-- 绝对定位 -->
              <div class="solution_select_right_box">
                <!-- 弹性布局 -->
                <div
                  class="box03"
                  v-on:mouseenter="mouChange03($event)"
                  v-on:mouseleave="mouChange003($event)"
                >
                  <div
                    class="solution_select_right_box_left03"
                    :class="mouseenter03 == true ? 'change05' : 'change005'"
                  >
                    <div style="width:1.5rem">卖场数字化</div>
                  </div>
                  <div
                    class="solution_select_right_box_right03"
                    :class="mouseenter03 == true ? 'change06' : 'change006'"
                  >
                    <div class="shadow_box"></div>
                    <div class="solution_select_right_box_right_absolute">
                      <div class="solution_select_right_box_right_flex">
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:1.625rem;height:2rem">
                            <img
                              src="../assets/paylookicon201.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>地图导航</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.75rem;height:2.875rem">
                            <img
                              src="../assets/paylookicon202.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>关联推荐</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.375rem;height:2.125rem">
                            <img
                              src="../assets/paylookicon203.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>组合推荐</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:1.9375rem;height:2rem">
                            <img
                              src="../assets/paylookicon204.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>线上导流</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="solution_select_right_box" style="top:50%">
                <div
                  class="box04"
                  v-on:mouseenter="mouChange04($event)"
                  v-on:mouseleave="mouChange004($event)"
                >
                  <div
                    class="solution_select_right_box_left04"
                    :class="mouseenter04 == true ? 'change07' : 'change007'"
                  >
                    <div style="width:1.5rem">营销多样化</div>
                  </div>
                  <div
                    class="solution_select_right_box_right04"
                    :class="mouseenter04 == true ? 'change08' : 'change008'"
                  >
                    <div class="shadow_box"></div>
                    <div class="solution_select_right_box_right_absolute">
                      <div class="solution_select_right_box_right_flex">
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.5rem;height:2.5rem">
                            <img
                              src="../assets/paylookicon205.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>千人千面</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.875rem;height:2.875rem">
                            <img
                              src="../assets/paylookicon206.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>精准推送</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:3.5rem;height:2.375rem">
                            <img
                              src="../assets/paylookicon207.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>云端资源</div>
                        </div>
                        <div class="solution_select_right_box_right_iconbox">
                          <div style="width:2.75rem;height:2.75rem">
                            <img
                              src="../assets/paylookicon208.png"
                              style="width:100%;height:100%"
                            />
                          </div>
                          <div>会员营销</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <el-button :type="showrightf" @click="showRight"><i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
          <el-button :type="showrightf" @click="showRight()" ref="solubtn02"
            ><i class="el-icon-arrow-right el-icon--right"></i
          ></el-button>
        </el-button-group>
      </div>
      <div class="solution_line pcOnly" ref="sunm117">
        <div class="solution_line_left" :class="{ active: selectnum }"></div>
        <div class="solution_line_left" :class="{ active: !selectnum }"></div>
      </div>

      <div class="solutions_mob mobOnly">
        <img src="../assets/solution1.png" alt="" />
        <img src="../assets/solution2.png" alt="" />
      </div>
    </div>
    <!-- 使用操作视频 -->
    <div class="usevideo">
      <div :class="{ tipBefore: true, tipAfter: tipShowVideo }" ref="videoRef">
        <div class="solution_title">使用操作视频</div>
      </div>
      <div class="usevideo_cont">
        <video
          id="myVideo"
          class="video-js"
          controls
          style="width:100%;height:100%"
        >
          <source
            src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/ieemoovideo.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <!-- 企业荣誉证书cards -->
    <div class="honor" ref="honorBox">
      <div :class="{ tipBefore: true, tipAfter: tipShowHonor }" ref="honorRef">
        <div class="solution_title">企业荣誉</div>
        <div class="solution_cont">在新零售领域不断进取探索</div>
      </div>
      <div class="honor_cards" ref="honorbig">
        <div class="honor_arrow" @click="arrowClick('prev')">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          class="cc"
          style="width:50%;height:100%;margin:0 5rem;overflow:inherit"
          ref="honSmallBox"
        >
          <el-carousel
            style="width:100%;height:100%;"
            :interval="4000"
            :type="carouselType"
            :autoplay="false"
            arrow="never"
            indicator-position="none"
            ref="cardShow"
          >
            <el-carousel-item v-for="(item, index) in pics" :key="index">
              <img :src="item.url" style="width:100%;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="honor_arrow" @click="arrowClick('next')">
          <i class="el-icon-arrow-right"></i>
        </div>
        <!-- </el-button-group> -->
      </div>
    </div>
    <!-- 合作企业 -->
    <div class="cooperative" ref="cooperBox">
      <div class="ccc pcOnly"></div>
      <div
        class="coo"
        style="position:absolute;z-index:100;width:100%;height:100% ;top:0;left:0"
      >
        <div
          style="display:flex;flex-direction:column;justify-content:flex-start;align-content:center;width:100%;height:100%"
        >
          <div
            :class="{ tipBefore: true, tipAfter: tipShowCooper }"
            ref="cooperRef"
          >
            <div
              class="cooperative_one"
              style="margin-bottom: 2rem"
              ref="cooperOne"
            >
              合作企业
            </div>
            <div class="solution_cont" ref="cooperTwo">深受行业信赖</div>
            <div class="solution_cont" ref="cooperThree">
              一起探索新零售的未来
            </div>
          </div>
          <div class="cooperative_picture">
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon09.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/pic05.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon03.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon10.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon05.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon06.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon07.png" />
            </div>
            <div class="cooperative_picture_box">
              <img src="../assets/coopericon08.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻动态展示 -->
    <div class="news">
      <div :class="{ tipBefore: true, tipAfter: tipShowNews }" ref="newsRef">
        <div class="newstitle">新闻动态</div>
        <div class="newsEnglishtitle">NEWS CENTER</div>
      </div>
      <div class="newsCont" ref="newsBox">
        <div class="newsCont_left pcOnly"></div>
        <div class="newsCont_right">
          <ul>
            <li @click="goNews14">
              <div class="inline">
                新款亿猫 AR·元宇宙概念智能购物车
              </div>
              <div class="inline_time">2022-11-25</div>
            </li>
            <li @click="goNews13">
              <div class="inline">
                成功进驻世界最大纯商业体-武商梦时代
              </div>
              <div class="inline_time">2022-11-17</div>
            </li>
            <li @click="goNews12">
              <div class="inline">
                第四家大型商超全店使用亿猫智能购物车！
              </div>
              <div class="inline_time">2022-09-30</div>
            </li>
            <li @click="goNews11">
              <div class="inline">
                亿猫速度！仅仅11天！第三家大型商超全店使用！
              </div>
              <div class="inline_time">2022-08-02</div>
            </li>
            <li @click="goNews8">
              <div class="inline">
                钛媒体2022年创新场景50 |
                实体门店转型难，数字化如何重构人、货、场关系
              </div>
              <div class="inline_time">2022-07-26</div>
            </li>
            <li @click="goNews10">
              <div class="inline">
                第二家全店使用智能购物车的大型商超，7月21日正式开业！
              </div>
              <div class="inline_time">2022-07-21</div>
            </li>
            <li @click="goNews9">
              <div class="inline">
                世纪联华杭州江晖店迎来智能购物车时代
              </div>
              <div class="inline_time">2022-05-27</div>
            </li>
            <li @click="goNews7">
              <div class="inline">
                36氪数智夜话直播 | 亿猫科技CEO：门店数智化机会在哪里？
              </div>
              <div class="inline_time">2022-05-19</div>
            </li>
            <li @click="goNews6">
              <div class="inline">
                亿猫科技入选2021年度南京市创新型领军企业培育库
              </div>
              <div class="inline_time">2021-12-10</div>
            </li>
            <li @click="goNews5">
              <div class="inline">"亿猫智能购物车"荣登2021CHINASHOP金翼榜</div>
              <div class="inline_time">2021-11-19</div>
            </li>
            <li @click="goNews4">
              <div class="inline">
                亿猫智能购物车助力『世纪联华鲸选宇宙空间站』首店开启
              </div>
              <div class="inline_time">2021-10-22</div>
            </li>
            <li @click="goNews1">
              <div class="inline">
                亿猫科技荣获上海国资国企数字化转型创新大赛[数字创新优胜奖]
              </div>
              <div class="inline_time">2021-07-22</div>
            </li>
            <li @click="goNews2">
              <div class="inline">这款黑科技会让你爱上逛超市，你信不信？</div>
              <div class="inline_time">2021-06-11</div>
            </li>
            <li @click="goNews3">
              <div class="inline">
                科技创新CEO特训营在京开营——亿猫科技创始人徐步兵先生入选
              </div>
              <div class="inline_time">2021-04-21</div>
            </li>
            <!-- <li>
                <div class="inline">亿猫科技荣获上海国资国企数字化转话成功以后</div>
                <div class="inline_time" >2021-04-21</div>
            </li>
            <li>
                <div class="inline">亿猫科技荣获上海国资国企数字化转话成功以后</div>
                <div class="inline_time">2021-04-21</div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- 脚步 -->
    <footers></footers>
  </div>
</template>
<script>
// 数字滚动
import countTo from "vue-count-to";
import footers from "../views/footer";
export default {
  components: {
    countTo,
    footers
  },
  watch: {
    "$route.path": "getPath",
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        const that = this;
        setTimeout(function() {
          // 打印screenWidth变化的值
          // console.log(that.screenWidth)
          that.timer = false;
        }, 400);
      }
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      mouseenter01: false,
      mouseenter02: false,
      mouseenter03: false,
      mouseenter04: false,
      currentScroll: 0,
      tipShowSolution: false,
      tipShowVideo: false,
      tipShowHonor: false,
      tipShowCooper: false,
      tipShowNews: false,
      // 解决方案左右按钮
      showleftf: "primary",
      showrightf: "info",
      selectnum: true,
      // 数字滚动
      startVal01: 0,
      endVal01: 0,
      startVal02: 0,
      endVal02: 0,
      startVal03: 0,
      endVal03: 0,
      startVal04: 0,
      endVal04: 0,
      showNub: true,
      //   企业荣誉左右按钮
      //   cardselsectL: 'primary',
      //   cardselsectR: 'info',
      //   轮播图片
      pics01: [
        { url: require("../assets/swiper02.png") },
        { url: require("../assets/swiper03.png") },
        { url: require("../assets/swiper01.png") }
      ],
      //   企业卡片
      pics: [
        { url: require("../assets/jinyibang.jpg") },
        { url: require("../assets/rengongzhinengxiinrui.jpg") },
        { url: require("../assets/jishuhangye.jpg") },
        { url: require("../assets/shuzichuangxinyouxiujiang.jpg") },
        { url: require("../assets/denglingqiye.jpg") },
        { url: require("../assets/xinruiqiyechuangxinjiang.jpg") },
        { url: require("../assets/denglingqiye.jpg") }
      ],
      carouselType: "card"
    };
  },
  mounted() {
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
      this.carouselType = "";
    } else {
      this.carouselType = "card";
    }
    const swiperBox = this.$refs.swiperBox;
    // console.log(swiperBox.style)
    const wImgbox = swiperBox.getBoundingClientRect().width;
    swiperBox.style.height = 0.5625 * wImgbox + "px";
    //  console.log(swiperBox.style.height)
    const NumBox = this.$refs.NumBox;
    NumBox.style.height = 0.507 * wImgbox + "px";
    const Txt01 = this.$refs.Txt01;
    Txt01.style.fontSize = 0.0406 * wImgbox + "px";
    const Txt02 = this.$refs.Txt02;
    Txt02.style.fontSize = 0.0406 * wImgbox + "px";
    const Txt03 = this.$refs.Txt03;
    Txt03.style.fontSize = 0.03125 * wImgbox + "px";
    const Txt04 = this.$refs.Txt04;
    Txt04.style.fontSize = 0.03125 * wImgbox + "px";
    const Txt05 = this.$refs.Txt05;
    Txt05.style.fontSize = 0.03125 * wImgbox + "px";
    const Txt06 = this.$refs.Txt06;
    Txt06.style.fontSize = 0.03125 * wImgbox + "px";
    const Txt07 = this.$refs.Txt07;
    Txt07.style.fontSize = 0.052 * wImgbox + "px";
    const Txt08 = this.$refs.Txt08;
    Txt08.style.fontSize = 0.052 * wImgbox + "px";
    const bigerNum01 = this.$refs.bigerNum01;
    bigerNum01.style.fontSize = 0.052 * wImgbox + "px";
    const bigerNum02 = this.$refs.bigerNum02;
    bigerNum02.style.fontSize = 0.052 * wImgbox + "px";
    const bigerNum03 = this.$refs.bigerNum03;
    bigerNum03.style.fontSize = 0.052 * wImgbox + "px";
    const bigerNum04 = this.$refs.bigerNum04;
    bigerNum04.style.fontSize = 0.052 * wImgbox + "px";
    const solutionbox01 = this.$refs.solutionbox01;
    solutionbox01.style.height = 0.234 * wImgbox + "px";
    const solutionbox02 = this.$refs.solutionbox02;
    solutionbox02.style.height = 0.234 * wImgbox + "px";
    const sonum01 = this.$refs.sonum01;
    sonum01.style.width = 0.0208 * wImgbox + "px";
    sonum01.style.top = 0.0229 * wImgbox + "px";
    sonum01.style.left = 0.038 * wImgbox + "px";
    const sonum02 = this.$refs.sonum02;
    sonum02.style.top = 0.0432 * wImgbox + "px";
    sonum02.style.left = 0.043 * wImgbox + "px";
    const sonum05 = this.$refs.sonum05;
    sonum05.style.fontSize = 0.01875 * wImgbox + "px";
    const sonum06 = this.$refs.sonum06;
    sonum06.style.fontSize = 0.01145 * wImgbox + "px";
    sonum06.style.marginTop = 0.00416 * wImgbox + "px";
    const sonum07 = this.$refs.sonum07;
    sonum07.style.marginTop = 0.026 * wImgbox + "px";
    const sonum08 = this.$refs.sonum08;
    sonum08.style.height = 0.048 * wImgbox + "px";
    sonum08.style.marginRight = 0.0156 * wImgbox + "px";
    const sonum11 = this.$refs.sonum11;
    sonum11.style.height = 0.048 * wImgbox + "px";
    sonum11.style.marginRight = 0.0156 * wImgbox + "px";
    const sonum09 = this.$refs.sonum09;
    sonum09.style.width = 0.02447 * wImgbox + "px";
    const sonum12 = this.$refs.sonum12;
    sonum12.style.width = 0.02447 * wImgbox + "px";
    const sonum10 = this.$refs.sonum10;
    sonum10.style.fontSize = 0.0104 * wImgbox + "px";
    const sonum13 = this.$refs.sonum13;
    sonum13.style.fontSize = 0.0104 * wImgbox + "px";
    const sonum14 = this.$refs.sonum14;
    sonum14.style.marginTop = 0.0208 * wImgbox + "px";
    const sonum15 = this.$refs.sonum15;
    sonum15.style.fontSize = 0.0109 * wImgbox + "px";
    sonum15.style.height = (50 / 1920) * wImgbox + "px";
    sonum15.style.width = (137 / 1920) * wImgbox + "px";
    const sonum16 = this.$refs.sonum16;
    sonum16.style.bottom = (75 / 1920) * wImgbox + "px";
    sonum16.style.right = (86 / 1920) * wImgbox + "px";
    sonum16.style.height = (321 / 1920) * wImgbox + "px";
    sonum16.style.width = (333 / 1920) * wImgbox + "px";
    const sonum001 = this.$refs.sonum001;
    sonum001.style.width = (110 / 1920) * wImgbox + "px";
    sonum001.style.top = 0.0229 * wImgbox + "px";
    sonum001.style.left = 0.038 * wImgbox + "px";
    const sonum002 = this.$refs.sonum002;
    sonum002.style.top = 0.0432 * wImgbox + "px";
    sonum002.style.left = 0.043 * wImgbox + "px";
    const sonum005 = this.$refs.sonum005;
    sonum005.style.fontSize = 0.01875 * wImgbox + "px";
    const sonum006 = this.$refs.sonum006;
    sonum006.style.fontSize = 0.01145 * wImgbox + "px";
    sonum006.style.marginTop = 0.00416 * wImgbox + "px";
    const sonum007 = this.$refs.sonum007;
    sonum007.style.marginTop = 0.026 * wImgbox + "px";
    const sonum008 = this.$refs.sonum008;
    sonum008.style.height = 0.048 * wImgbox + "px";
    sonum008.style.marginRight = 0.0156 * wImgbox + "px";
    const sonum011 = this.$refs.sonum011;
    sonum011.style.height = 0.048 * wImgbox + "px";
    sonum011.style.marginRight = 0.0156 * wImgbox + "px";
    const sonum009 = this.$refs.sonum009;
    sonum009.style.width = (52 / 1920) * wImgbox + "px";
    const sonum012 = this.$refs.sonum012;
    sonum012.style.width = (53 / 1920) * wImgbox + "px";
    const sonum010 = this.$refs.sonum010;
    sonum010.style.fontSize = 0.0104 * wImgbox + "px";
    const sonum013 = this.$refs.sonum013;
    sonum013.style.fontSize = 0.0104 * wImgbox + "px";
    const sonum014 = this.$refs.sonum014;
    sonum014.style.marginTop = 0.0208 * wImgbox + "px";
    const sonum015 = this.$refs.sonum015;
    sonum015.style.fontSize = 0.0109 * wImgbox + "px";
    sonum015.style.height = (47 / 1920) * wImgbox + "px";
    sonum015.style.width = (129 / 1920) * wImgbox + "px";
    const sonum016 = this.$refs.sonum016;
    sonum016.style.bottom = (75 / 1920) * wImgbox + "px";
    sonum016.style.right = (86 / 1920) * wImgbox + "px";
    sonum016.style.height = (321 / 1920) * wImgbox + "px";
    sonum016.style.width = (333 / 1920) * wImgbox + "px";
    const sunm117 = this.$refs.sunm117;
    sunm117.style.width = (500 / 1920) * wImgbox + "px";
    const cooperBox = this.$refs.cooperBox;
    cooperBox.style.height = (972 / 1920) * wImgbox + "px";
    const cooperOne = this.$refs.cooperOne;
    cooperOne.style.fontSize = (45 / 1920) * wImgbox + "px";
    cooperOne.style.marginTop = (60 / 1920) * wImgbox + "px";
    const cooperTwo = this.$refs.cooperTwo;
    cooperTwo.style.fontSize = (38 / 1920) * wImgbox + "px";
    cooperTwo.style.marginTop = (20 / 1920) * wImgbox + "px";
    const cooperThree = this.$refs.cooperThree;
    cooperThree.style.fontSize = (22 / 1920) * wImgbox + "px";
    cooperThree.style.marginTop = (20 / 1920) * wImgbox + "px";
    const newsBox = this.$refs.newsBox;
    newsBox.style.height = (525 / 1920) * wImgbox + "px";
    newsBox.style.marginTop = (90 / 1920) * wImgbox + "px";
    const honorBox = this.$refs.honorBox;
    honorBox.style.height = (920 / 1920) * wImgbox + "px";
    const honSmallBox = this.$refs.honSmallBox;
    honSmallBox.style.width = (1000 / 1920) * wImgbox + "px";
    honSmallBox.style.height = (348 / 1920) * wImgbox + "px";
    honSmallBox.style.marginRight = (80 / 1920) * wImgbox + "px";
    honSmallBox.style.marginLeft = (80 / 1920) * wImgbox + "px";
    const Num001 = this.$refs.Num001;
    Num001.style.marginTop = (70 / 1920) * wImgbox + "px";
    const Num002 = this.$refs.Num002;
    Num002.style.marginTop = (70 / 1920) * wImgbox + "px";
    const Num003 = this.$refs.Num003;
    Num003.style.marginTop = (70 / 1920) * wImgbox + "px";
    const Num004 = this.$refs.Num004;
    Num004.style.marginTop = (70 / 1920) * wImgbox + "px";
    window.addEventListener("scroll", this.showIcon);
    const honorbig = this.$refs.honorbig;
    honorbig.style.marginTop = (100 / 1920) * wImgbox + "px";
    window.onresize = () => {
      return (() => {
        // window.screenWidth = document.body.clientWidth
        // this.screenWidth = window.screenWidth
        const swiperBox = this.$refs.swiperBox;
        const wImgbox = swiperBox.getBoundingClientRect().width;
        //   console.log(wImgbox)
        swiperBox.style.height = 0.5625 * wImgbox + "px";
        this.$nextTick(() => {
          const swiperBox = this.$refs.swiperBox;
          const wImgbox = swiperBox.getBoundingClientRect().width;
          //   console.log(wImgbox)
          swiperBox.style.height = 0.5625 * wImgbox + "px";
          //   console.log(swiperBox.style.height)
          const NumBox = this.$refs.NumBox;
          const WNumBox = swiperBox.getBoundingClientRect().width;
          NumBox.style.height = 0.507 * WNumBox + "px";
          const Txt01 = this.$refs.Txt01;
          Txt01.style.fontSize = 0.0406 * WNumBox + "px";
          const Txt02 = this.$refs.Txt02;
          Txt02.style.fontSize = 0.0406 * WNumBox + "px";
          const Txt03 = this.$refs.Txt03;
          Txt03.style.fontSize = 0.03125 * WNumBox + "px";
          const Txt04 = this.$refs.Txt04;
          Txt04.style.fontSize = 0.03125 * WNumBox + "px";
          const Txt05 = this.$refs.Txt05;
          Txt05.style.fontSize = 0.03125 * WNumBox + "px";
          const Txt06 = this.$refs.Txt06;
          Txt06.style.fontSize = 0.03125 * WNumBox + "px";
          const Txt07 = this.$refs.Txt07;
          Txt07.style.fontSize = 0.052 * WNumBox + "px";
          const Txt08 = this.$refs.Txt08;
          Txt08.style.fontSize = 0.052 * WNumBox + "px";
          const bigerNum01 = this.$refs.bigerNum01;
          bigerNum01.style.fontSize = 0.052 * WNumBox + "px";
          const bigerNum02 = this.$refs.bigerNum02;
          bigerNum02.style.fontSize = 0.052 * WNumBox + "px";
          const bigerNum03 = this.$refs.bigerNum03;
          bigerNum03.style.fontSize = 0.052 * WNumBox + "px";
          const bigerNum04 = this.$refs.bigerNum04;
          bigerNum04.style.fontSize = 0.052 * WNumBox + "px";
          const solutionbox01 = this.$refs.solutionbox01;
          solutionbox01.style.height = 0.234 * WNumBox + "px";
          const solutionbox02 = this.$refs.solutionbox02;
          solutionbox02.style.height = 0.234 * WNumBox + "px";
          const sonum01 = this.$refs.sonum01;
          sonum01.style.width = 0.0208 * WNumBox + "px";
          sonum01.style.top = 0.0229 * WNumBox + "px";
          sonum01.style.left = 0.038 * WNumBox + "px";
          const sonum02 = this.$refs.sonum02;
          sonum02.style.top = 0.0432 * WNumBox + "px";
          sonum02.style.left = 0.043 * WNumBox + "px";
          const sonum05 = this.$refs.sonum05;
          sonum05.style.fontSize = 0.01875 * WNumBox + "px";
          const sonum06 = this.$refs.sonum06;
          sonum06.style.fontSize = 0.01145 * WNumBox + "px";
          sonum06.style.marginTop = 0.00416 * WNumBox + "px";
          const sonum07 = this.$refs.sonum07;
          sonum07.style.marginTop = 0.026 * WNumBox + "px";
          const sonum08 = this.$refs.sonum08;
          sonum08.style.height = 0.048 * WNumBox + "px";
          const sonum11 = this.$refs.sonum11;
          sonum11.style.height = 0.048 * WNumBox + "px";
          const sonum09 = this.$refs.sonum09;
          sonum09.style.width = 0.02447 * WNumBox + "px";
          const sonum12 = this.$refs.sonum12;
          sonum12.style.width = 0.02447 * WNumBox + "px";
          const sonum10 = this.$refs.sonum10;
          sonum10.style.fontSize = 0.0104 * WNumBox + "px";
          const sonum13 = this.$refs.sonum13;
          sonum13.style.fontSize = 0.0104 * WNumBox + "px";
          const sonum14 = this.$refs.sonum14;
          sonum14.style.marginTop = 0.0208 * WNumBox + "px";
          const sonum15 = this.$refs.sonum15;
          sonum15.style.fontSize = 0.0109 * WNumBox + "px";
          sonum15.style.height = (47 / 1920) * WNumBox + "px";
          sonum15.style.width = (129 / 1920) * WNumBox + "px";
          const sonum16 = this.$refs.sonum16;
          sonum16.style.bottom = (75 / 1920) * WNumBox + "px";
          sonum16.style.right = (86 / 1920) * WNumBox + "px";
          sonum16.style.height = (321 / 1920) * WNumBox + "px";
          sonum16.style.width = (333 / 1920) * WNumBox + "px";
          const sonum001 = this.$refs.sonum001;
          sonum001.style.width = (110 / 1920) * WNumBox + "px";
          sonum001.style.top = 0.0229 * WNumBox + "px";
          sonum001.style.left = 0.038 * WNumBox + "px";
          const sonum002 = this.$refs.sonum002;
          sonum002.style.top = 0.0432 * WNumBox + "px";
          sonum002.style.left = 0.043 * WNumBox + "px";
          const sonum005 = this.$refs.sonum005;
          sonum005.style.fontSize = 0.01875 * WNumBox + "px";
          const sonum006 = this.$refs.sonum006;
          sonum006.style.fontSize = 0.01145 * WNumBox + "px";
          sonum006.style.marginTop = 0.00416 * WNumBox + "px";
          const sonum007 = this.$refs.sonum007;
          sonum007.style.marginTop = 0.026 * WNumBox + "px";
          const sonum008 = this.$refs.sonum008;
          sonum008.style.height = 0.048 * WNumBox + "px";
          sonum008.style.marginRight = 0.0156 * WNumBox + "px";
          const sonum011 = this.$refs.sonum011;
          sonum011.style.height = 0.048 * WNumBox + "px";
          sonum011.style.marginRight = 0.0156 * WNumBox + "px";
          const sonum009 = this.$refs.sonum009;
          sonum009.style.width = (52 / 1920) * WNumBox + "px";
          const sonum012 = this.$refs.sonum012;
          sonum012.style.width = (53 / 1920) * WNumBox + "px";
          const sonum010 = this.$refs.sonum010;
          sonum010.style.fontSize = 0.0104 * WNumBox + "px";
          const sonum013 = this.$refs.sonum013;
          sonum013.style.fontSize = 0.0104 * WNumBox + "px";
          const sonum014 = this.$refs.sonum014;
          sonum014.style.marginTop = 0.0208 * WNumBox + "px";
          const sonum015 = this.$refs.sonum015;
          sonum015.style.fontSize = 0.0109 * WNumBox + "px";
          sonum015.style.height = (50 / 1920) * WNumBox + "px";
          sonum015.style.width = (137 / 1920) * WNumBox + "px";
          const sonum016 = this.$refs.sonum016;
          sonum016.style.bottom = (75 / 1920) * WNumBox + "px";
          sonum016.style.right = (86 / 1920) * WNumBox + "px";
          sonum016.style.height = (321 / 1920) * WNumBox + "px";
          sonum016.style.width = (333 / 1920) * WNumBox + "px";
          const sunm117 = this.$refs.sunm117;
          sunm117.style.width = (500 / 1920) * WNumBox + "px";
          const cooperBox = this.$refs.cooperBox;
          cooperBox.style.height = (972 / 1920) * WNumBox + "px";
          const cooperOne = this.$refs.cooperOne;
          cooperOne.style.fontSize = (45 / 1920) * WNumBox + "px";
          cooperOne.style.marginTop = (60 / 1920) * WNumBox + "px";
          const cooperTwo = this.$refs.cooperTwo;
          cooperTwo.style.fontSize = (38 / 1920) * WNumBox + "px";
          cooperTwo.style.marginTop = (20 / 1920) * WNumBox + "px";
          const cooperThree = this.$refs.cooperThree;
          cooperThree.style.fontSize = (22 / 1920) * WNumBox + "px";
          cooperThree.style.marginTop = (20 / 1920) * WNumBox + "px";
          const newsBox = this.$refs.newsBox;
          newsBox.style.height = (525 / 1920) * WNumBox + "px";
          newsBox.style.marginTop = (90 / 1920) * WNumBox + "px";
          const honorBox = this.$refs.honorBox;
          honorBox.style.height = (920 / 1920) * WNumBox + "px";
          const honSmallBox = this.$refs.honSmallBox;
          honSmallBox.style.width = (1000 / 1920) * WNumBox + "px";
          honSmallBox.style.height = (348 / 1920) * WNumBox + "px";
          honSmallBox.style.marginRight = (80 / 1920) * WNumBox + "px";
          honSmallBox.style.marginLeft = (80 / 1920) * WNumBox + "px";
          const honorbig = this.$refs.honorbig;
          honorbig.style.marginTop = (100 / 1920) * WNumBox + "px";
          const Num001 = this.$refs.Num001;
          Num001.style.marginTop = (70 / 1920) * WNumBox + "px";
          const Num002 = this.$refs.Num002;
          Num002.style.marginTop = (70 / 1920) * WNumBox + "px";
          const Num003 = this.$refs.Num003;
          Num003.style.marginTop = (70 / 1920) * WNumBox + "px";
          const Num004 = this.$refs.Num004;
          Num004.style.marginTop = (70 / 1920) * WNumBox + "px";
          //   const cardShow = this.$refs.honSmallBox
          //   console.log(cardShow.style.width)
        });
      })();
    };
  },
  methods: {
    enter: function(e) {
      //   console.log(e)
    },
    leave: function(e) {
      //   console.log(e)
    },
    // 数字滚动
    showIcon() {
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 400 &&
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop < 1700
      ) {
        // this.countTo.start();
        this.showNub = false;
        this.endVal01 = 1;
        this.endVal02 = 6;
        this.endVal03 = 99.9;
        this.endVal04 = 100000;
      } else {
        this.showNub = true;
        // this.countTo.reset();
        this.endVal01 = 0;
        this.endVal02 = 0;
        this.endVal03 = 0;
        this.endVal04 = 0;
      }
      this.currentScroll = window.pageYOffset; // 表示当前滚动的位置
      //   console.log(this.currentScroll);
      if (this.currentScroll >= this.$refs.solutionRef.offsetTop - 700) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowSolution = true;
      }
      if (this.currentScroll >= this.$refs.videoRef.offsetTop - 700) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowVideo = true;
      }
      if (this.currentScroll >= this.$refs.honorRef.offsetTop - 700) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowHonor = true;
      }
      if (this.currentScroll >= this.$refs.cooperRef.offsetTop - 700) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowCooper = true;
      }
      if (this.currentScroll >= this.$refs.newsRef.offsetTop - 700) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowNews = true;
      }
    },
    //   解决方案展示
    showLeft() {
      this.showleftf = "primary";
      this.showrightf = "info";
      this.selectnum = true;
    },
    showRight() {
      this.showrightf = "primary";
      this.showleftf = "info";
      this.selectnum = false;
    },
    // 查看更多跳转
    jumpSolvePay() {
      this.$router.push({ path: "/paysolve" });
    },
    jumpSolve() {
      this.$router.push({ path: "/solve" });
    },
    //  企业荣誉走马灯卡片左右按钮切换功能
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
        // this.cardselsectL = 'info';
        // this.cardselsectR = 'primary';
      } else {
        this.$refs.cardShow.prev();
        // this.cardselsectL = 'primary';
        // this.cardselsectR = 'info';
      }
    },
    goNews1() {
      this.$router.push("/newspage1");
    },
    goNews2() {
      this.$router.push("/newspage2");
    },
    goNews3() {
      this.$router.push("/newspage3");
    },
    goNews4() {
      this.$router.push("/newspage4");
    },
    goNews5() {
      this.$router.push("/newspage5");
    },
    goNews6() {
      this.$router.push("/newspage6");
    },
    goNews7() {
      this.$router.push("/newspage7");
    },
    goNews8() {
      this.$router.push("/newspage8");
    },
    goNews9() {
      this.$router.push("/newspage9");
    },
    goNews10() {
      this.$router.push("/newspage10");
    },
    goNews11() {
      this.$router.push("/newspage11");
    },
    goNews12() {
      this.$router.push("/newspage12");
    },
    goNews13() {
      this.$router.push("/newspage13");
    },
    goNews14() {
      this.$router.push("/newspage14");
    },
    mouChange01() {
      this.mouseenter01 = true;
    },
    mouChange001() {
      this.mouseenter01 = false;
    },
    mouChange02() {
      this.mouseenter02 = true;
    },
    mouChange002() {
      this.mouseenter02 = false;
    },
    mouChange03() {
      this.mouseenter03 = true;
    },
    mouChange003() {
      this.mouseenter03 = false;
    },
    mouChange04() {
      this.mouseenter04 = true;
    },
    mouChange004() {
      this.mouseenter04 = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showIcon);
    window.onresize = null;
  }
};
</script>
<style lang="less" scoped>
/* 轮播 */
.swiper_box {
  // width: 100%;
  // min-width: 100%;
  // max-width: 100%;
  // height: 970px;
  // position: fixed;
  width: 100%;
  height: calc(1080 / 1080 * 100vh);
  // height: 1080px;
  // max-height: calc(100vh - 0px);
  // min-height: 60.625rem;
  // max-width: 60.625rem;
  padding: 0;

  .el-carousel {
    width: 100%;
    height: 100%;
    // height: 67.5rem;
    // width: 100% !important;
    // background-size: cover;
  }
  .el-carousel-item {
    width: 100%;
    height: 100%;
  }
  .el-carousel__container {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-carousel__arrow {
    width: 5.25rem;
    height: 5.25rem;
    font-size: 40px;
    font-weight: 700;
    margin: 0 5.0625rem;
    // border: 3px solid #FFFFFF;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }
  ::v-deep.el-carousel__arrow:hover {
    background: rgba(255, 255, 255, 0.8);
    color: #000;
  }
}
.swiper_box :hover {
  ::v-deep .el-carousel__arrow {
    width: 5.25rem;
    height: 5.25rem;
    font-size: 40px;
    font-weight: 700;
    margin: 0 5.0625rem;
    // border: 3px solid #FFFFFF;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
  ::v-deep.el-carousel__arrow:hover {
    background: rgba(255, 255, 255, 0.8);
    color: #000;
  }
}

html,
body {
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
}
.box {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: column;
  justify-items: center;
}
// 首页轮播草纸

/* 数字滚动样式 */
.scrollNum {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  height: calc(956 / 1080 * 100vh);
  height: 956px;
  flex-wrap: wrap;
  overflow: hidden;
  color: cornsilk;
  position: relative;
}
.aaa {
  position: absolute;
  // background-color: rgba(255, 103, 0, 0.59) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.scrollNum_box1,
.scrollNum_box2,
.scrollNum_box3,
.scrollNum_box4 {
  width: 50%;
  height: 50%;
  // background-color: rgba(255,255,255,0.2) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 0px;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bbb {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.scrollNum_box1,
.scrollNum_box2,
.scrollNum_box3,
.scrollNum_box4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.scrollNum_box1 {
  background-image: url(../assets/scrollnum01.png);
}
.scrollNum_box2 {
  background-image: url(../assets/scrollnum02.png);
}
.scrollNum_box3 {
  background-image: url(../assets/scrollnum03.png);
}
.scrollNum_box4 {
  background-image: url(../assets/scrollnum04.png);
}
// .scrollNum_box img{
//     width: 6.25rem;
//     height: 6.25rem;
// }
.scrollNum_box_topstyle {
  //  position: absolute;
  z-index: 100;
  font-size: 100px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  margin: 70px 0 0 0;
  line-height: 0.625rem;
  //  transform: translate(-50%, -50%);
  //  left: 50%;
  //  top:10%;
  //  width: 100%;
  //  text-align: center;
}
.scrollNum_box_topstyle_txt {
  font-size: 4.875rem;
  font-weight: 300;
  font-family: Source Han Sans CN;
  margin: 0;
  // margin-left: calc(10 / 1080 * 100vh);
}
.scrollNum_box_bottomstyle {
  // position: absolute;
  z-index: 100;
  margin: 0;
  // transform: translate(-50%, -50%);
  // left: 50%;
  // bottom: 5%;
  // width: 100%;
  // line-height: calc(92 / 1080 * 100vh);
}
.scrollNum_box_bottomstyle_txt {
  font-size: 3.75rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
/* 解决方案样式 */
.solution {
  background: #fff;
  height: 51.25rem;
  width: 100%;
  // background: chartreuse;
}
.tipBefore {
  transform: translateY(12.5rem); //滚动前的位置
  opacity: 0; //滚动前设置透明隐藏
}
.tipAfter {
  transform: translateY(0%) !important; //滚动后的位置
  opacity: 1 !important; //滚动后显现
  transition: all 0.5s ease-out;
}
.solution_title {
  //    transition: 0.6s;
  //    visibility: 0;
  //    opacity: 0;
  //    width: 0;
  margin-top: 3.125rem;
  width: 100%;
  font-size: 2.25rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #010101;
  // background:#ff6801;
  line-height: 6.6875rem;
}
.solution_cont {
  margin-top: 0.625rem;
  // background:#000;
  width: 100%;
  font-size: 1.375rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6a6a6a;
}
.solution_cards {
  width: 100%;
  // background: #000;
}
.el-button-group {
  /* display: inline-block; */
  vertical-align: middle;
  // margin: 0 auto;
  height: 100%;
  display: flex !important;
  // background: rgba(255,103,0,0.59);
  justify-content: center;
  align-items: center;
}
i.el-icon-arrow-left {
  text-align: center;
  display: flex;
  justify-content: center;
}
.el-icon--right {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}
.el-button--primary {
  color: #fff;
  background-color: #ff6700 !important;
  border-color: #ff6700 !important;
  width: 3% !important;
  height: 3.25rem !important;
  font-size: 2rem !important;
  text-align: center;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.el-button--info {
  color: #fff;
  background-color: #313131 !important;
  border-color: #313131 !important;
}
.el-button-group .el-button--primary:not(:first-child):not(:last-child) {
  width: 3% !important;
  height: 3.25rem !important;
  font-size: 2rem !important;
}
.el-button-group .el-button--info:last-child {
  width: 3% !important;
  height: 3.25rem !important;
  font-size: 2rem !important;
}
.el-button {
  padding: 0.75rem !important;
}
.el-button-group .el-button--info:first-child {
  border-right-color: rgba(255, 255, 255, 0.5);
  width: 3% !important;
  height: 3.25rem !important;
  font-size: 2rem !important;
}
.solution_select {
  width: 58%;
  height: 28.125rem;
  // background: cornflowerblue;
  // margin-top: 50px;
  margin-left: 3.125rem;
  margin-right: 3.125rem;
  display: flex;
}
.solution_select_left {
  width: 54%;
  height: 100%;
  background: #ff6700;
  position: relative;
  cursor: pointer;
}
.solution_select_right {
  width: 46%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-start;
}
// 3 阴影盒子
.shadow_box {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7) !important;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  color: #fff;
}
.solution_select_right_box {
  width: 100%;
  height: 50%;
  color: #fff;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;

  .box01 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-image: url(../assets/payrightback1.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    overflow: hidden;

    .threebone {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      left: 90%;
    }
    .change01 {
      animation: myMou01 0.6s;
      animation-fill-mode: forwards;
    }
    .change001 {
      animation: myMou001 0.6s;
      animation-fill-mode: forwards;
    }

    @keyframes myMou01 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou001 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }

    .change02 {
      animation: myMou02 0.6s;
      animation-fill-mode: forwards;
    }
    .change002 {
      animation: myMou002 0.6s;
      animation-fill-mode: forwards;
    }
    @keyframes myMou02 {
      0% {
        left: 90%;
        top: 0;
      }
      100% {
        left: 0;
        top: 0;
      }
    }
    @keyframes myMou002 {
      0% {
        left: 0;
        top: 0;
      }
      100% {
        left: 90%;
        top: 0;
      }
    }
  }
  .box02 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-image: url(../assets/payrightback2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    overflow: hidden;

    .change03 {
      animation: myMou03 0.6s;
      animation-fill-mode: forwards;
    }
    .change003 {
      animation: myMou003 0.6s;
      animation-fill-mode: forwards;
    }
    .change04 {
      animation: myMou04 0.6s;
      animation-fill-mode: forwards;
    }
    .change004 {
      animation: myMou004 0.6s;
      animation-fill-mode: forwards;
    }
    @keyframes myMou03 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou003 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
    @keyframes myMou04 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou004 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
  }
  .box03 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-image: url(../assets/payrightback201.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    overflow: hidden;

    .change05 {
      animation: myMou05 0.6s;
      animation-fill-mode: forwards;
    }
    .change005 {
      animation: myMou005 0.6s;
      animation-fill-mode: forwards;
    }
    .change06 {
      animation: myMou06 0.6s;
      animation-fill-mode: forwards;
    }
    .change006 {
      animation: myMou006 0.6s;
      animation-fill-mode: forwards;
    }
    @keyframes myMou05 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou005 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
    @keyframes myMou06 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou006 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
  }
  .box04 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-image: url(../assets/payrightback202.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    overflow: hidden;

    .change07 {
      animation: myMou07 0.6s;
      animation-fill-mode: forwards;
    }
    .change007 {
      animation: myMou007 0.6s;
      animation-fill-mode: forwards;
    }
    .change08 {
      animation: myMou08 0.6s;
      animation-fill-mode: forwards;
    }
    .change008 {
      animation: myMou008 0.6s;
      animation-fill-mode: forwards;
    }
    @keyframes myMou07 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou007 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
    @keyframes myMou08 {
      0% {
        left: 90%;
      }
      100% {
        left: 0;
      }
    }
    @keyframes myMou008 {
      0% {
        left: 0;
      }
      100% {
        left: 90%;
      }
    }
  }
}
// 1  这是标题盒子
.solution_select_right_box_left01 {
  width: 10%;
  height: 100%;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #010101;
  //    position: relative;
  //    left:90%;
}
.solution_select_right_box_left02 {
  width: 10%;
  height: 100%;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #010101;
  position: relative;
  left: 90%;
}
.solution_select_right_box_left03 {
  width: 10%;
  height: 100%;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #010101;
  position: relative;
  left: 90%;
}
.solution_select_right_box_left04 {
  width: 10%;
  height: 100%;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #010101;
  position: relative;
  left: 90%;
}
.solution_select_right_box_right {
  width: 90%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //    background-image: url(../assets/payrightback1.png);
  //    background-repeat: no-repeat;
  //    background-size: 100% 100%;
  position: relative;
  //    left:90%;
}
.solution_select_right_box_right02 {
  width: 90%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //    background-image: url(../assets/payrightback2.png);
  //    background-repeat: no-repeat;
  //    background-size: 100% 100%;
  position: relative;
  left: 90%;
}
.solution_select_right_box_right03 {
  width: 90%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //    background-image: url(../assets/payrightback201.png);
  //    background-repeat: no-repeat;
  //    background-size: 100% 100%;
  position: relative;
  left: 90%;
}
.solution_select_right_box_right04 {
  width: 90%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //    background-image: url(../assets/payrightback202.png);
  //    background-repeat: no-repeat;
  //    background-size: 100% 100%;
  position: relative;
  left: 90%;
}
// 2  这个是右侧主要的大盒子
.solution_select_right_box_right_absolute {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.solution_select_right_box_right_flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.solution_select_right_box_right_iconbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 5.5rem;
  font-size: 1rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.solution_line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 31.25rem;
  height: 0.125rem;
  padding: 0;
  background: #b5b5b5;
  margin: 3.75rem auto 0 auto;
}
.solution_line_left {
  width: 50%;
  height: 100%;
}

.solution_line_left.active {
  background: #ff6700;
}
.solution_select_left_layerone {
  width: 2.5rem;
  // height:79px;
  position: absolute;
  // top:44px;
  // left:65px;
  z-index: 10;
}
.solution_select_left_contentMix {
  position: absolute;
  // top:83px;
  // left:80px;
  z-index: 99;
  width: 100%;
}
.solution_select_left_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.solution_select_left_contentone_top {
  // font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.solution_select_left_contentone_bot {
  // font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  // margin-top: 8px;
}
.solution_select_left_contenttwo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3.125rem;
}
.solution_select_left_contenttwoMin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 6.25rem;
  margin-right: 1.875rem;
}
.solution_select_left_layertwo {
  width: 20.8125rem;
  height: 20.0625rem;
  position: absolute;
  bottom: 4.6875rem;
  right: 5.375rem;
  z-index: 100;
}
.solution_select_left_contentonelook {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 2.5rem;
}
.solution_select_left_contentonelook_btn {
  width: 8.5625rem;
  height: 3.125rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5625rem;
  font-size: 1.3125rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ff6700;
}
/* 使用操作视频 */
.usevideo_cont {
  width: 58%;
  margin: 2.5rem auto 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #010101;
}
.usevideo {
  background: #fff;
  // height: 60.625rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
/* 企业证书 */
.honor {
  height: 880px;
  width: 100%;
  background: #eeeeee;
  // background: chartreuse;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.honor_cards {
  width: 70%;
  // height: 800px;
  // height: 20px;
  margin: 100px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: chartreuse;
  // margin-bottom: 30px;
}
::v-deep .honor .el-carousel__container {
  //   height: 300px !important;
  width: 100% !important;
  //   margin-bottom: 160px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  //   background: chocolate;
}
.honor .el-carousel__item--card {
  // width: 33.25rem !important;
  // height: 23.5625rem !important;
}
.honoipic {
  width: 100%;
  height: 100%;
}
.honor .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
::v-deep .honor .el-carousel__button {
  width: 0.875rem !important;
  height: 0.875rem !important;
  border-radius: 50% !important;
  margin-right: 1.25rem;
  background: #a0a0a0;
  opacity: 1;
}
::v-deep .honor .el-carousel__indicator.is-active button {
  /* opacity: 1; */
  background: #ff6700;
  box-shadow: 0px 0px 0.3125rem 0.3125rem #fff,
    0px 0px 0.3125rem 0.375rem #ff6700;
}
::v-deep .honor .el-carousel .el-carousel--horizontal .el-carousel--card {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  // background: cornflowerblue !important;
}
.honor_arrow {
  width: 4.4375rem;
  height: 4.4375rem;
  text-align: center;
  border-radius: 50% !important;
  background: #9b9b9b;
  color: #fff;
  font-size: 2.8125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.honor_arrow :hover,
.honor_arrow :visited {
  width: 4.4375rem;
  height: 4.4375rem;
  text-align: center;
  border-radius: 8rem;
  box-sizing: border-box;
  font-size: 2.8125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff6700;
}
/* 合作企业 */
.cooperative {
  height: calc(972 / 1080 * 100vh);
  width: 100%;
  background-image: url(../assets/cooperbackground.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2) !important;
  position: relative;
  overflow: hidden;
}
.ccc {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7) !important;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: #fff;
}
.cooperative_one {
  // position: absolute;
  // z-index: 100;
  width: 100%;
  // height: 50px;
  font-size: calc(45 / 1080 * 100vh);
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  margin-top: 60px;
  // line-height: calc(107 / 1080 * 100vh);
  // top:20px;
}
.cooperative_two {
  // position: absolute;
  // z-index: 100;
  width: 100%;
  // height: 50px;
  font-size: calc(38 / 1080 * 100vh);
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  // line-height: calc(50 / 1080 * 100vh);
  margin-top: calc(20 / 1080 * 100vh);
  // top:100px;
}
.cooperative_three {
  // position: absolute;
  // z-index: 100;
  width: 100%;
  // height: 30px;
  font-size: calc(22 / 1080 * 100vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: calc(20 / 1080 * 100vh);
  // line-height: 6.4375rem;
  // top:160px;
}
.cooperative_picture {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  width: 70%;
  // height:37.5rem;
  justify-content: space-around;
  position: absolute;
  z-index: 100;
  // bottom: 11.25rem;
  top: 65%;
  left: 50%;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cooperative_picture_box {
  width: 22%;
  // height: 13.125rem;
  // height: 100%;
  border: 1px dashed #949494;
  background: #fff;
  margin-bottom: calc(40 / 1080 * 100vh);
  overflow: hidden;
}
.cooperative_picture_box img:hover {
  transform: scale(1.06);
}
.cooperative_picture_box img {
  width: 100%;
  // height: 100%;
  cursor: pointer;
  transition: all 1s ease 0s;
}
/* 新闻动态展示 */
.news {
  width: 100%;
  height: 62.5rem;
  background: #eeeeee;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.newstitle {
  width: 100%;
  height: 6.25rem;
  font-size: 2.8125rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #020202;
  line-height: 6.6875rem;
  margin-top: 3.125rem;
}
.newsEnglishtitle {
  width: 14.375rem;
  height: 1.875rem;
  font-size: 1.1875rem;
  margin: 0 auto;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #191919;
  line-height: 28px;
  border: 0.0625rem solid #010101;
  text-align: center;
}
.newsCont {
  width: 68%;
  height: 525px;
  background: #fff;
  margin: 5.625rem auto 0 auto;
  display: flex;
}
.newsCont_left {
  width: 27%;
  height: 100%;
  background: darkgoldenrod;
  background-image: url(../assets/news_left.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.newsCont_right {
  width: 73%;
  height: 95%;
  color: #fff;
  display: flex;
  overflow-y: auto;
  justify-content: center;
}
.newsCont_right ul {
  list-style-type: none;
  width: 90%;
  height: 100%;
  //overflow:hiddden;
  padding: 0;
  // margin: 1.25rem auto 1.25rem auto;
}
.newsCont_right ul li {
  border-bottom: 0.0625rem solid #e5e5e5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 1.75rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  // width: 54.125rem;
  width: 100%;
  color: #292929;
  line-height: 3.75rem;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}
.newsCont_right ul li :hover {
  color: #2277da;
  cursor: pointer;
}
.newsCont_right ul li :nth-child(2) {
  color: #292929;
}
.inline {
  // display:inline;
  width: 53%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // display: flex;
  text-align: left;
  // justify-content: flex-start;
}
.inline_time {
  display: inline;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #292929;
}
@media screen and (max-width: 767px) {
  .el-main {
    overflow-x: hidden;
  }
  .swiper_box {
    overflow-x: hidden;
    margin-bottom: 20px;
    .el-carousel {
      transform: scale(1.3);
      transform-origin: center;
    }
  }
  .solution_cont {
    margin-top: 0;
    font-size: 1.375rem !important;
  }
  .scrollNum {
    color: #fff;
    display: block;
    height: auto !important;
    .scrollNum_box1,
    .scrollNum_box2,
    .scrollNum_box3,
    .scrollNum_box4 {
      width: 100%;
      height: 50vw;
      background-size: cover !important;
    }
    .scrollNum_box_topstyle {
      margin-top: 50px !important;
    }
    .scrollNum_box_topstyle_num {
      font-size: 60px !important;
    }
    .scrollNum_box_bottomstyle_txt {
      font-size: 36px !important;
      margin-top: 50px !important;
    }
  }

  .usevideo_cont {
    width: 90%;
    margin: 1rem 5%;
  }
  .honor {
    margin-bottom: 3rem;
    height: auto !important;
    .honor_cards {
      width: 100%;
    }
    .honor_arrow {
      width: 3rem !important;
      height: 3rem !important;
      font-size: 1.5rem;
      display: inline-block !important;
      i {
        display: flex !important;
        width: 3rem !important;
        height: 3rem !important;
        font-size: 1.5rem;
        display: inline-block !important;
        &:before {
          top: 7px !important;
          position: relative;
        }
      }
    }
    .cc {
      width: 70% !important;
      min-height: 210px !important;
    }
    .el-carousel__item:nth-child(2n + 1) {
      background-color: transparent;
    }
  }

  .cooperative {
    background: none;
    height: auto !important;
    .coo {
      position: relative !important;
      height: auto !important;
    }
    .cooperative_one,
    .cooperative_two,
    .cooperative_three {
      color: #010101;
    }
    .cooperative_one {
      font-size: 2.25rem !important;
    }
    .cooperative_picture {
      width: 100%;
      background: #ececec;
      padding: 5%;
      box-sizing: border-box;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      display: block;
      margin: 1rem auto 0;
      > div {
        width: 44%;
        float: left;
        border: none;
        margin: 3%;
        &:nth-child(2n + 1) {
          clear: both;
        }
      }
    }
  }
  .solution {
    height: auto;
    .solutions_mob {
      margin: 0 5%;
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }

  .news {
    background: #fff;
    height: auto !important;
    .newstitle {
      margin-top: 2rem;
    }
    .inline_time {
      font-size: 1.4rem;
    }
    .newsEnglishtitle {
      line-height: 1.875rem;
    }
    .newsCont {
      width: 100%;
      height: auto !important;
      max-height: 30rem;
      margin-bottom: 4rem;
      .newsCont_right {
        width: 100%;
        height: auto;
        ul {
          height: auto;
          li {
            margin: 0;
            padding: 4px 10px;
            box-sizing: border-box;
            display: block;
            line-height: 2;
            text-align: left;
            > div:nth-child(1) {
              width: 100%;
            }
            > div:nth-child(2) {
              justify-content: left;
              top: -0.4rem;
              position: relative;
              color: #666;
            }
          }
        }
      }
    }
  }
}
</style>
